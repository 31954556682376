.alive-tabs {
  height: 45px;
  // margin-bottom: 15px;
  padding: 0;
  line-height: 45px;
  background: #fff;
  // overflow: hidden;
  white-space: nowrap;

  li {
    list-style: none;
    cursor: pointer;
  }

  li {
    display: inline-block;
    border-right: solid 1px #e6e6e6;
    padding: 0 36px 0 20px;
    position: relative;
    transition: background 0.2s;
    background-color: #fff;

    &.active {
      background: rgba(#1890ff, 0.3);
    }
  }

  .close-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }
}

